<template>
<div>
  <!-- Breadcrumb Component -->
  <breadcrumb-area :breadcrumb="breadcrumb"/>
  <!-- Expert Area Component -->
  <expert-area/>
</div>
</template>

<script>
import BreadcrumbArea from "../components/BreadcrumbArea";
import ExpertArea from "../components/ExpertArea";
export default {
  name: "Team",
  components: {
    ExpertArea,
    BreadcrumbArea
  },
  data() {
    return {
      breadcrumb: {
        page: "Our Team",
        pageTitle: "Our Team"
      }
    }
  }
}
</script>

<style scoped>

</style>