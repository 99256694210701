<template>
  <!-- Team Area Start -->
  <section class="ekifa-team-area section_t_80 section_b_50">
      <b-container>
        <b-row>
          <b-col>
            <div class="site-heading">
              <h2>our Experts</h2>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
            </div>
          </b-col>
        </b-row>
   <b-row>
     <b-col md="6" lg="3">
       <div class="single-team">
         <div class="team-image">
           <img :src="require('../assets/img/agnt1.jpg')" alt="team member" />
         </div>
         <div class="team-info">
           <div class="team-overlay-one">
             <h3>Jamil Fares</h3>
             <p>Founder & Lawyer</p>
           </div>
         </div>
         <div class="team-info-two">
           <div class="team-overlay-two">
             <ul>
               <li class="social-icon-margin"><a href="#"><i class="fa fa-facebook"></i></a></li>
               <li class="social-icon-margin"><a href="#"><i class="fa fa-twitter"></i></a></li>
               <li class="social-icon-margin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
               <li class="social-icon-margin"><a href="#"><i class="fa fa-google-plus"></i></a></li>
             </ul>
           </div>
         </div>
       </div>
     </b-col>
     <b-col md="6" lg="3">
       <div class="single-team">
         <div class="team-image">
           <img :src="require('../assets/img/agnt2.jpg')" alt="team member" />
         </div>
         <div class="team-info">
           <div class="team-overlay-one">
             <h3>Christelle Helou</h3>
             <p>Marketing & Finance Director</p>
           </div>
         </div>
         <div class="team-info-two">
           <div class="team-overlay-two">
             <ul>
               <li class="social-icon-margin"><a href="#"><i class="fa fa-facebook"></i></a></li>
               <li class="social-icon-margin"><a href="#"><i class="fa fa-twitter"></i></a></li>
               <li class="social-icon-margin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
               <li class="social-icon-margin"><a href="#"><i class="fa fa-google-plus"></i></a></li>
             </ul>
           </div>
         </div>
       </div>
     </b-col>
     <b-col md="6" lg="3">
       <div class="single-team">
         <div class="team-image">
           <img :src="require('../assets/img/agnt3.jpg')" alt="team member" />
         </div>
         <div class="team-info">
           <div class="team-overlay-one">
             <h3>Laywer 2 Name</h3>
             <p>Senior Consultant</p>
           </div>
         </div>
         <div class="team-info-two">
           <div class="team-overlay-two">
             <ul>
               <li class="social-icon-margin"><a href="#"><i class="fa fa-facebook"></i></a></li>
               <li class="social-icon-margin"><a href="#"><i class="fa fa-twitter"></i></a></li>
               <li class="social-icon-margin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
               <li class="social-icon-margin"><a href="#"><i class="fa fa-google-plus"></i></a></li>
             </ul>
           </div>
         </div>
       </div>
     </b-col>
     <b-col md="6" lg="3">
       <div class="single-team">
         <div class="team-image">
           <img :src="require('../assets/img/agnt4.jpg')" alt="team member" />
         </div>
         <div class="team-info">
           <div class="team-overlay-one">
             <h3>Lawyer 3 name</h3>
             <p>Partner & Lawyer</p>
           </div>
         </div>
         <div class="team-info-two">
           <div class="team-overlay-two">
             <ul>
               <li class="social-icon-margin"><a href="#"><i class="fa fa-facebook"></i></a></li>
               <li class="social-icon-margin"><a href="#"><i class="fa fa-twitter"></i></a></li>
               <li class="social-icon-margin"><a href="#"><i class="fa fa-linkedin"></i></a></li>
               <li class="social-icon-margin"><a href="#"><i class="fa fa-google-plus"></i></a></li>
             </ul>
           </div>
         </div>
       </div>
     </b-col>
   </b-row>
      </b-container>
  </section>
  <!-- Team Area End -->
</template>

<script>
export default {
  name: "ExpertArea"
}
</script>

<style scoped>
.social-icon-margin{
  margin: 1px;
}
</style>